<template>
    <footer class="relative bg-black backdrop-opacity-0">
        <div class="mx-auto max-w-7xl my-6 px-6 lg:px-12 2lg:px-12 2xl:px-0">
            <!-- Vertical layout -->
            <div class="flex flex-col space-y-8 md:space-y-12">
                <!-- Logo + Addresses row -->
                <div class="flex flex-col md:flex-row space-x-0 md:space-x-4 space-y-4 md:space-y-0">
                    <!-- Logo -->
                    <div class="md:basis-1/6 self-center md:self-start">
                        <a :href="route('home')" :aria-label="$page.props.appName">
                            <JalorLogoVertical class="h-28 w-auto" :alt="$page.props.appName" />
                        </a>
                    </div>
                    <!-- Legal Address -->
                    <div v-if="hasCompanyInfo" class="md:basis-2/6 text-white">
                        <h2 class="footer-header">{{ $t('frontend.footer.legal_office') }}</h2>
                        <p>{{ $page.props.legalInfo.legal_name }}</p>
                        <p>{{ $page.props.legalInfo.legal_address }}</p>
                        <p>
                            {{ $page.props.legalInfo.legal_zip }} {{ $page.props.legalInfo.legal_city }},
                            {{ $page.props.legalInfo.legal_country }}
                        </p>
                        <p>{{ `${$t('frontend.footer.phone')} ${$page.props.legalInfo.legal_phone}` }}</p>
                        <p>PEC: jalorsrl@legalmail.it</p>
                    </div>
                    <!-- Export Address -->
                    <div v-if="hasCompanyInfo" class="md:basis-2/6 text-white">
                        <h2 class="footer-header">{{ $t('frontend.footer.export_office') }}</h2>
                        <p>{{ $page.props.legalInfo.legal_name }}</p>
                        <p>{{ $page.props.legalInfo.export_address }}</p>
                        <p>
                            {{ $page.props.legalInfo.export_zip }} {{ $page.props.legalInfo.export_city }},
                            {{ $page.props.legalInfo.export_country }}
                        </p>
                        <p>{{ `${$t('frontend.footer.phone')} ${$page.props.legalInfo.export_phone}` }}</p>
                        <!-- <p>
                            <a :href="`mailto:${$page.props.legalInfo.export_email}`">{{
                                    $page.props.legalInfo.export_email
                                }}</a>
                        </p> -->
                    </div>
                    <!-- Social Info -->
                    <div v-if="hasSocialInfo" class="md:basis-1/6">
                        <h2 class="footer-header">{{ $t('frontend.contact_form.follow_us') }}</h2>
                        <div class="flex flex-row space-x-2 items-center mt-2">
                            <div v-if="$page.props.socialInfo.social_facebook">
                                <a
                                    :href="$page.props.socialInfo.social_facebook"
                                    target="_blank"
                                    class="flex flex-row items-center"
                                    aria-label="Facebook"
                                    ><FacebookIcon class="w-10 text-white" alt="Facebook" />
                                </a>
                            </div>
                            <div v-if="$page.props.socialInfo.social_instagram">
                                <a
                                    :href="$page.props.socialInfo.social_instagram"
                                    target="_blank"
                                    class="flex flex-row items-center"
                                    aria-label="Instagram"
                                    ><InstagramIcon class="w-10 text-white" alt="Instagram"
                                /></a>
                            </div>
                            <div v-if="$page.props.socialInfo.social_linkedin">
                                <a
                                    :href="$page.props.socialInfo.social_linkedin"
                                    target="_blank"
                                    class="flex flex-row items-center"
                                    aria-label="LinkedIn"
                                    ><LinkedInIcon class="w-10 text-white" alt="LinkedIn"
                                /></a>
                            </div>
                            <div v-if="$page.props.socialInfo.social_threads">
                                <a
                                    :href="$page.props.socialInfo.social_threads"
                                    target="_blank"
                                    class="flex flex-row items-center"
                                    aria-label="Threads"
                                    ><ThreadsIcon class="w-10 text-white" alt="Threads"
                                /></a>
                            </div>
                            <!-- <div v-if="$page.props.socialInfo.social_twitter">
                                <a
                                    :href="$page.props.socialInfo.social_twitter"
                                    target="_blank"
                                    class="flex flex-row items-center" aria-label="X (Twitter)"
                                ><TwitterIcon class="w-10-12 text-white" alt="X (Twitter)"
                                /></a>
                            </div> -->
                            <div v-if="$page.props.socialInfo.social_youtube">
                                <a
                                    :href="$page.props.socialInfo.social_youtube"
                                    target="_blank"
                                    class="flex flex-row items-center"
                                    aria-label="YouTube"
                                    ><YouTubeIcon class="w-10 text-white" alt="YouTube"
                                /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Copyright + Powered by row -->
                <div class="flex flex-wrap justify-between gap-3 text-white text-sm">
                    <span
                        >&copy; {{ $page.props.legalInfo.legal_name }} 2024 -
                        <!-- {{ $t('frontend.footer.present') }} - -->
                        {{ $t('frontend.footer.vat_number') }}: {{ $page.props.legalInfo.legal_vat_number }} -
                        {{ $t('frontend.footer.share_capital') }} - {{ $t('frontend.footer.rea') }} -
                        <!-- {{ $t('frontend.footer.all_rights_reserved') }} - -->
                        <a :href="route('policy.show')" class="font-bold">{{ $t('Privacy Policy') }}</a>
                    </span>
                    <span class="text-left md:text-right justify-end"
                        >{{ $t('frontend.footer.designed_by') }}
                        <a href="https://generalservice.na.it" target="_blank" rel="nofollow" class="font-bold"
                            >General Service</a
                        >
                    </span>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
import {computed} from 'vue';
import {usePage} from '@inertiajs/vue3';
import {JalorLogoVertical} from '@/images';
import {FacebookIcon, InstagramIcon, LinkedInIcon, ThreadsIcon, YouTubeIcon} from '@/images/frontend/social_media';

const hasCompanyInfo = computed(() => usePage().props.legalInfo && Object.keys(usePage().props.legalInfo)?.length);
const hasSocialInfo = computed(() => usePage().props.socialInfo && Object.keys(usePage().props.socialInfo)?.length);
</script>
