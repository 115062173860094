<template>
    <ul class="flex w-full flex-col items-start justify-start px-6 py-4 text-left md:px-14 2xl:px-16">
        <li
            class="group w-full border-b pr-1 text-white duration-200 hover:border-primary md:cursor-pointer xl:block xl:hidden border-secondary">
            <InertiaLink :href="route('about')">
                <div
                    class="flex justify-between py-5 text-2xl font-medium duration-200 group-hover:text-primary sm:leading-9 md:text-3xl md:leading-[50px] text-white"
                    :class="{'!text-primary': route().current('about')}">
                    {{ $t('frontend.about_us') }}
                </div>
            </InertiaLink>
        </li>
        <!-- Products menu -->
        <li
            class="group w-full border-b pr-1 text-white duration-200 hover:border-secondary md:cursor-pointer xl:block xl:hidden border-secondary">
            <div class="group relative font-medium md:cursor-pointer">
                <button
                    type="button"
                    aria-haspopup="menu"
                    aria-expanded="false"
                    data-headlessui-state=""
                    class="flex w-full items-center justify-between py-5 text-2xl font-medium leading-9 duration-300 group-hover:text-primary md:cursor-pointer md:text-3xl md:leading-[50px] text-white"
                    :class="{
                        '!text-primary': route().current('categories.index') || route().current('categories.show'),
                    }"
                    @click="toggleProductsMenu">
                    {{ $t('frontend.products') }}
                    <ArrowRight
                        class="h-auto w-3 duration-300 group-hover:text-primary"
                        :class="[{'rotate-90': !productsMenuOpened}, {'-rotate-90': productsMenuOpened}]" />
                </button>
                <!-- Products dropdown mobile menu -->
                <ul v-if="productsMenuOpened" role="menu" tabindex="0">
                    <li class="duration-200 border-b border-secondary" role="menuitem" tabindex="-1">
                        <InertiaLink
                            :href="route('categories.index')"
                            class="flex items-center justify-between border-b py-5 pl-4 pr-2 last:border-0"
                            :class="{'!text-primary': route().current('categories.index')}">
                            <span class="text-sm font-medium uppercase tracking-widest">
                                {{ $t('frontend.all_categories') }}
                            </span>
                            <ArrowRight class="h-3 w-3 text-primary" />
                        </InertiaLink>
                    </li>
                    <li v-for="(name, slug) in $page.props.parentCategories" :key="slug" role="menuitem" tabindex="-1">
                        <InertiaLink
                            :href="route('categories.show', {categories: slug})"
                            class="flex items-center justify-between border-b py-5 pl-4 pr-2 last:border-0"
                            :class="{'!text-primary': route().current('categories.show', {categories: slug})}">
                            <span class="text-sm font-medium uppercase tracking-widest">{{ name }}</span>
                            <ArrowRight class="h-3 w-3 text-primary" />
                        </InertiaLink>
                    </li>
                </ul>
            </div>
        </li>
        <li
            class="group w-full border-b pr-1 text-white duration-200 hover:border-primary md:cursor-pointer xl:block xl:hidden border-secondary">
            <InertiaLink :href="route('why')">
                <div
                    class="flex justify-between py-5 text-2xl font-medium duration-200 group-hover:text-primary sm:leading-9 md:text-3xl md:leading-[50px] text-white"
                    :class="{'!text-primary': route().current('why')}">
                    {{ $t('frontend.why', {app_name: $page.props.appName.toString()}) }}
                </div>
            </InertiaLink>
        </li>
        <li
            class="group w-full border-b pr-1 text-white duration-200 hover:border-primary md:cursor-pointer xl:block xl:hidden border-secondary">
            <InertiaLink :href="route('technology')">
                <div
                    class="flex justify-between py-5 text-2xl font-medium duration-200 group-hover:text-primary sm:leading-9 md:text-3xl md:leading-[50px] text-white"
                    :class="{'!text-primary': route().current('technology')}">
                    {{ $t('frontend.technology', {app_name: $page.props.appName.toString()}) }}
                </div>
            </InertiaLink>
        </li>
        <li
            class="group w-full border-b pr-1 text-white duration-200 hover:border-primary md:cursor-pointer xl:block xl:hidden border-secondary">
            <InertiaLink :href="route('treatments')">
                <div
                    class="flex justify-between py-5 text-2xl font-medium duration-200 group-hover:text-primary sm:leading-9 md:text-3xl md:leading-[50px] text-white"
                    :class="{'!text-primary': route().current('treatments')}">
                    {{ $t('frontend.treatments') }}
                </div>
            </InertiaLink>
        </li>
        <li
            class="group w-full border-b pr-1 text-white duration-200 hover:border-primary md:cursor-pointer xl:block xl:hidden border-secondary">
            <InertiaLink :href="route('events')">
                <div
                    class="flex justify-between py-5 text-2xl font-medium duration-200 group-hover:text-primary sm:leading-9 md:text-3xl md:leading-[50px] text-white"
                    :class="{'!text-primary': route().current('events')}">
                    {{ $t('frontend.events') }}
                </div>
            </InertiaLink>
        </li>
        <li class="mt-10 w-full xl:hidden">
            <a
                :href="route('contact.index')"
                class="group relative inline-block px-[30px] text-center text-sm font-medium uppercase tracking-widest text-primary-content duration-300 w-full md:cursor-pointer"
                disabled="false">
                <!-- <Angle class="absolute left-0.5 top-0 h-full w-[30px] text-secondary" /> -->
                <div
                    class="flex h-full flex-col justify-center bg-primary px-4 py-3.5 duration-150 group-hover:shadow-[0px_25px_46px_rgba(19,43,255,0.2)]">
                    <p class="text-lg leading-9 tracking-normal md:text-3xl">{{ $t('frontend.contact.title') }}</p>
                </div>
                <!-- <Angle class="absolute right-0.5 top-0 h-full w-[30px] rotate-180 text-secondary" /> -->
            </a>
        </li>
    </ul>
</template>
<script setup>
import {ref} from 'vue';
import {route as routeFn} from 'ziggy-js';
import {Link as InertiaLink} from '@inertiajs/vue3';
// import Angle from '@/images/frontend/angle.svg';
import ArrowRight from '@/images/frontend/arrow-right.svg';

const productsMenuOpened = ref(routeFn().current('categories.index') || routeFn().current('categories.show'));

const toggleProductsMenu = () => (productsMenuOpened.value = !productsMenuOpened.value);
</script>
