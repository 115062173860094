<template>
    <div class="flex w-full mx-auto max-w-7xl px-6 lg:px-12 xl:px-6 2xl:px-0">
        <!-- Logo -->
        <a :href="route('home')" aria-current="page" :aria-label="$page.props.appName">
            <JalorLogo class="h-auto w-48 md:cursor-pointer xl:w-52" />
        </a>
        <!-- Separator -->
        <!-- <hr class="mx-4 h-auto self-stretch border-r border-secondary" /> -->
        <!-- Menu -->
        <div class="flex flex-1 items-center justify-end">
            <!-- Desktop menu -->
            <nav class="mr-8">
                <DesktopNavItems />
            </nav>
            <!-- Flat Locale Switcher -->
            <FlatLocaleSwitcher />
            <!-- Mobile menu -->
            <div class="flex ml-4">
                <!-- Mobile menu icon -->
                <button
                    type="button"
                    aria-expanded="false"
                    class="h-full border-0 outline-0 dropdown"
                    :aria-label="$t('frontend.navigation_menu')"
                    @click="toggleMenu">
                    <span
                        class="flex flex-col duration-200 hover:opacity-80 xl:ml-5 xl:cursor-pointer xl:hidden"
                        role="img"
                        :aria-label="$t('frontend.navigation_menu')">
                        <template v-if="show">
                            <!-- Close icon -->
                            <div
                                class="mb-2.5 h-0.5 w-6 origin-top-left rounded-lg bg-white transition -translate-y-[1.5px] rotate-45 translate-x-[4.5px]"></div>
                            <div
                                class="h-0.5 w-6 origin-bottom-left rounded-lg bg-white transition translate-y-[3px] -rotate-45 translate-x-[4.5px]"></div>
                        </template>
                        <template v-else>
                            <!-- Menu icon -->
                            <div
                                class="mb-2.5 h-0.5 w-6 origin-top-left rounded-lg bg-white transition translate-y-0 rotate-0"></div>
                            <div
                                class="h-0.5 w-6 origin-bottom-left rounded-lg bg-white transition translate-y-0 rotate-0"></div>
                        </template>
                    </span>
                </button>
                <!-- Mobile menu -->
                <nav
                    class="bg-black fixed top-0 -z-10 mx-auto flex h-screen w-full flex-col items-center justify-between overflow-scroll bg-j-dark-background-2 pb-4 pt-32 ease-in 2xl:hidden"
                    :class="animationClass"
                    tabindex="-1">
                    <MobileNavItems />
                    <!-- Mobile Footer -->
                    <div class="px-6">
                        <ul class="item-center mt-8 flex justify-center gap-8">
                            <li v-if="$page.props.socialInfo.social_facebook">
                                <a :href="$page.props.socialInfo.social_facebook" rel="noopener" target="_blank">
                                    <FacebookIcon class="h-auto w-12 text-white" aria-label="Facebook" />
                                </a>
                            </li>
                            <li v-if="$page.props.socialInfo.social_instagram">
                                <a :href="$page.props.socialInfo.social_instagram" rel="noopener" target="_blank">
                                    <InstagramIcon class="h-auto w-12 text-white" aria-label="Instagram" />
                                </a>
                            </li>
                            <li v-if="$page.props.socialInfo.social_linkedin">
                                <a :href="$page.props.socialInfo.social_linkedin" rel="noopener" target="_blank">
                                    <LinkedInIcon class="h-auto w-12 text-white" aria-label="LinkedIn" />
                                </a>
                            </li>
                            <li v-if="$page.props.socialInfo.social_threads">
                                <a :href="$page.props.socialInfo.social_threads" rel="noopener" target="_blank">
                                    <ThreadsIcon class="h-auto w-12 text-white" aria-label="Threads" />
                                </a>
                            </li>
                            <!-- <li v-if="$page.props.socialInfo.social_twitter">
                                <a :href="$page.props.socialInfo.social_twitter" rel="noopener" target="_blank">
                                    <TwitterIcon class="h-auto w-12 text-white" aria-label="Twitter" />
                                </a>
                            </li> -->
                            <li v-if="$page.props.socialInfo.social_youtube">
                                <a :href="$page.props.socialInfo.social_youtube" rel="noopener" target="_blank">
                                    <YouTubeIcon class="h-auto w-12 text-white" aria-label="YouTube" />
                                </a>
                            </li>
                        </ul>
                        <p
                            class="mx-auto mt-8 text-center text-xs font-medium uppercase leading-4 tracking-widest text-white">
                            &copy; {{ $page.props.legalInfo.legal_name }} 2024 - {{ $t('frontend.footer.present') }} -
                            {{ $t('frontend.footer.vat_number') }}: {{ $page.props.legalInfo.legal_vat_number }}
                        </p>
                        <p
                            class="mx-auto mt-3 break-words text-center text-xs font-medium uppercase leading-4 tracking-widest text-white">
                            <span class="opacity-70">{{ $t('frontend.footer.designed_by') }}</span
                            >&nbsp;<a href="https://generalservice.na.it" target="_blank" rel="nofollow"
                                >General Service</a
                            >
                        </p>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>
<script setup>
import {computed, ref} from 'vue';
import {FlatLocaleSwitcher} from '@/Components/Theme';
import DesktopNavItems from '@/Layouts/Partials/Frontend/DesktopNavItems.vue';
import MobileNavItems from '@/Layouts/Partials/Frontend/MobileNavItems.vue';
import {JalorLogo} from '@/images';
import {FacebookIcon, InstagramIcon, LinkedInIcon, ThreadsIcon, YouTubeIcon} from '@/images/frontend/social_media';

const show = ref(false);

const toggleMenu = () => {
    show.value = !show.value;
};

const animationClass = computed(() => ({
    'transition-all duration-200': true,
    'left-0': show.value,
    'left-full': !show.value,
}));
</script>
