<template>
    <ul class="flex space-x-5">
        <li v-for="availableLocale in availableLocales" :key="availableLocale">
            <div v-if="activeLocale === availableLocale" class="!cursor-default">
                <component
                    :is="getLocaleFlag(availableLocale)"
                    class="w-6"
                    :aria-label="$t(`locale.${availableLocale}`)" />
            </div>
            <a v-else :href="route(routeName, {locale: availableLocale})" :aria-label="$t(`locale.${availableLocale}`)">
                <component
                    :is="getLocaleFlag(availableLocale)"
                    class="w-6"
                    :aria-label="$t(`locale.${availableLocale}`)" />
            </a>
        </li>
    </ul>
</template>

<script setup>
import {usePage} from '@inertiajs/vue3';
import EnglishFlag from '@/images/components/flag_en.svg';
import ItalianFlag from '@/images/components/flag_it.svg';

const props = defineProps({
    currentLocale: {
        type: String,
        default: null,
    },
    routeName: {
        type: String,
        default: 'locale.update',
    },
});

const activeLocale = props.currentLocale ?? usePage().props?.locale ?? 'en';
const availableLocales = ['it', 'en'];

const getLocaleFlag = (locale) => {
    switch (locale) {
        case 'it':
            return ItalianFlag;
        case 'en':
        default:
            return EnglishFlag;
    }
};
</script>
